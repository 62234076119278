import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import style from './Text.module.scss';

/**
 * Name: Text
 * Desc: Render Text
 * @param {string} text
 * @param {node} children
 * @param {string} className
 * @param {string} color
 * @param {string} fontFamily
 * @param {string} fontSize
 */
const Text = ({
  text,
  children,
  className,
  color,
  fontFamily,
  fontSize,
  as: Component,
  ...rest
}) => {
  const textStyle = classnames(
    style.textWrapper,

    {
      [className]: !!className,
      [style[`${color}Color`]]: !!color,
      [style[fontFamily]]: !!fontFamily,
      [style[fontSize]]: !!fontSize,
    },
  );
  return (
    <Component className={textStyle} {...rest}>
      {text}
      {children}
    </Component>
  );
};

Text.defaultProps = {
  as: 'span',
  color: 'lightBlack',
  fontFamily: 'light',
  fontSize: 'lg',
  className: '',
  children: '',
  text: '',
};

Text.propTypes = {
  as: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'dark',
    'lightGrey',
    'lightBlack',
    'danger',
    'tertiary',
    'white',
    'lightBlueText',
    'skyBlue',
    'darkGrayButton',
    'inputBorder',
    'secondary',
    'timer',
    'darkGrayTxt',
    'modalErrorText',
    'textBorderColor',
    'lightBlackTxt',
  ]),
  fontFamily: PropTypes.oneOf(['regular', 'medium', 'semiBold', 'bold']),
  fontSize: PropTypes.oneOf([
    'xxxs',
    'xxs',
    'xs',
    'sm',
    'md',
    'lg',
    'xlg',
    'xxlg',
    'fsxl',
    'fs2xl',
    'fs3xl',
    'fs4xl',
    'fs5xl',
  ]),
};

export default Text;
