import { logout } from 'app/redux/actions/authActions';
import caretDownWhite from 'assets/images/arrow-down.svg';
import caretUpWhite from 'assets/images/arrow-up.svg';
import caretUp from 'assets/images/caret-down-up-white.svg';
import caretDown from 'assets/images/caret-down-white.svg';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useGoogleLogout } from 'react-google-login';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import {
  ADMIN_USER_LIST_PATH,
  LOGIN_PAGE_PATH,
  PROFILE_PAGE_PATH,
} from 'routes/appRoutes';
import Image from 'shared/Image';
import MediaImage from 'shared/MediaImage';
import Text from 'shared/Text';
import { getLocalStorage, removeLocalStorage } from 'utils/utils';
import style from './HeaderDropDown.module.scss';

const getUserName = (userName) => {
  if (userName) {
    const [firstName = '', lastName = ''] = userName?.split(' ');
    return { firstName, lastName };
  }
  return { firstName: '', lastName: '' };
};

/**
 * Name: HeaderDropDown
 * Desc: Render headerDropDown of app
 * @param {bool} addVideoLink
 */
const HeaderDropDown = ({ userInfo, onClickAddVideoModal, dropDownColor }) => {
  const {
    user_id: userId = '',
    token = '',
    user_is_admin: isAdmin = true,
    user_name: userName,
    user_profile_pic: userProfilePicUrl = '',
    user_profile_pic_color: profileColor = '',
  } = userInfo;

  const { firstName, lastName } = getUserName(userName);
  const dispatch = useDispatch();
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const onLogoutSuccess = () => {};
  const onFailure = () => {};
  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    onLogoutSuccess,
    onFailure,
  });

  const handleLogout = (e, item) => {
    if (item.id === 'logOut') {
      dispatch(logout({ userId, token }));
      signOut(); // Logout From Google
      const fbResponse = getLocalStorage('FB_RESPONSE');

      if (fbResponse.length > 0) {
        window.FB.api('/me/permissions', 'delete', null, () =>
          window.FB.logout(),
        );
        removeLocalStorage('FB_RESPONSE');
        console.log('fbResponseRemoved', fbResponse);
      }
    }
  };

  const profilePath = `/user-profile/${userId}`;

  const menuItems = [
    {
      id: 'myProfile',
      linkName: 'My Profile',
      linkAddress: profilePath,
    },
    {
      id: 'myAccount',
      linkName: 'My Account',
      linkAddress: PROFILE_PAGE_PATH,
    },

    {
      id: 'logOut',
      linkName: 'Log Out',
      linkAddress: LOGIN_PAGE_PATH,
    },
  ];

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className="customDropdown">
        <div className={style.userDropdownWrapper}>
          <MediaImage
            imagePath={userProfilePicUrl}
            className={style.userImage}
            firstName={firstName}
            lastName={lastName}
            profileColor={profileColor}
            fontSize="sm"
            imageSize="sm"
            height="32px"
            width="32px"
          />
          {dropDownColor === 'black' ? (
            <Image
              height="7px"
              width="10px"
              name="profile"
              src={dropdownOpen ? caretUpWhite : caretDownWhite}
              color="white"
            />
          ) : (
            <Image
              height="7px"
              width="10px"
              name="profile"
              src={dropdownOpen ? caretUp : caretDown}
              color="white"
            />
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropDowns">
        <div className={style.userDropdown}>
          <ul>
            <li className={style.addVideoLink}>
              <Link to="#" onClick={onClickAddVideoModal}>
                <Text fontSize="lg" text="Add Video" color="lightBlack" />
              </Link>
            </li>
            {isAdmin && (
              <li key="admin">
                <Link to={ADMIN_USER_LIST_PATH}>
                  <Text fontSize="lg" text="Admin" color="lightBlack" />
                </Link>
              </li>
            )}
            {menuItems.map((item) => (
              <li key={item.id}>
                <Link
                  to={item.linkAddress}
                  onClick={(e) => handleLogout(e, item)}
                >
                  <Text fontSize="lg" text={item.linkName} color="lightBlack" />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
HeaderDropDown.propTypes = {
  userInfo: PropTypes.object,
  onClickAddVideoModal: PropTypes.func,
  dropDownColor: PropTypes.string,
};
export default HeaderDropDown;
