export const ENCRYPTION_KEY = 'skoodio_';

export const ERROR_PREFIX = 'Error :';
export const MIN_PASSWORD_LENGTH = 3;
export const MAX_PASSWORD_LENGTH = 16;

export const APIS = {
  STUDENT_UPLOAD_FILE: 'videos/upload-video',
  STUDENT_UPLOAD_IMAGE: 'videos/upload-image',
  STUDENT_TITLE_DESC_SAVE: 'students/save',
  POST_REVIEW_SAVE: 'students/save-feedback',
  GET_REVIEW_LIST: 'students/get-listing',
  DELETE_FEEDBACK_POST: 'students/delete-comments',
  POST_REGISTRATION_DATA: 'students/user-signup',
  USER_DELETE: 'students/user-delete',
  CHECK_EMAIL_EXISTS: 'students/check-email-exists',
  POST_FORGOT_PASSWORD_DATA: 'students/forgot-password',
  POST_RESET_PASSWORD_DATA: 'students/reset-password',
  LOGIN: 'students/login',
  VERIFY_ACCOUNT: 'students/account-verification',
  LOGOUT: 'students/logout',
  ADD_TO_MY_VIDEO: 'videos/save-upload-video',
  DELETE_MY_VIDEO: 'videos/delete-uploaded-video',
  GET_MY_VIDEOS: 'videos/get-videos',
  MY_VIDEO_REVIEW: 'videos/video-reviews',
  DELETE_MY_VIDEO_POST: 'videos/delete-my-videos',
  USER_LIST: 'students/get/user-listing',
  UPDATE_TITLE: 'videos/update-uploaded-video?_id=',
  SEND_INVITED_USERS: 'videos/inviting-users',
  INVITE_REVIEWERS_LIST: 'videos/invite-reviewers', // get invited user also
  REMOVE_INVITE_REVIEWERS: 'videos/invite-reviewers',
  OWNER_INFORMATION: 'videos/accepting-shared-review',
  ADD_TO_FAV: 'videos/fav-review-action',
  DELETE_REVIEW: 'videos/reviews-details',
  UPDATE_REVIEW_DETAILS: 'videos/review-details?_id=',
  GET_SHARE_REVIEW_DATA: 'videos/review-details',
  GET_MY_USERS: 'students/user-listing',
  FOLLOWING_USER: 'students/follower',
  POST_FEEDBACK_COMMENTS: 'comments/feedback-comments',
  GET_MY_NOTIFICATIONS: 'notifications/listing-user-notifications',
  UPDATE_MY_NOTIFICATIONS: 'notifications/update-notifications',
  DELETE_MY_NOTIFICATIONS: 'notifications/delete-notifications',
  POST_FEEDBACK_COMMENTS_REPLY: 'comments/comment-replies',
  UPDATE_PROFILE_DATA: 'students/update_user_profile?_id=',
  CLOSE_USER_ACCOUNT: 'students/close-user-account',
  POST_REGISTRATION_VERIFY: 'students/resend-verification-email',
  GET_REGISTRATION_DATA: 'students/get/user-signup-details?passCode=',
  UPDATE_USER_STATUS: 'students/update-user-status?_id=',
  GET_USER_COMMENTS_DETAIL: 'comments/get-user-comments-details',
  GET_ALL_COMMENTS_DETAIL: 'comments/get-all-comments-details',
  ADD_COLLECTION: 'collections/save-collection',
  GET_ALL_COLLECTION: 'collections/get-collections',
  ADD_VIDEO_TO_COLLECTION: 'collections/save-collection-videos',
  UPDATE_COLLECTION: 'collections/update-collection',
  DELETE_MY_COLLECTION_POST: 'collections/delete-my-collections',
  COLLECTION_VIDEO_ORDER: 'collections/update-collection-videos-order',
  GET_LINKED_VIDEO_REVIEW_LIST: 'videos/review-lists',
  DELETE_COMMENT: 'comments/delete-comments',
  DELETE_REPLAY: 'comments/delete-replies',
  UPDATE_COMMENT: 'comments/update-comments-details',
  UPDATE_REPLIES: 'comments/update-replies-details',
  REMOVE_VIDEO_FROM_COLLECTION: 'collections/delete-my-collection-videos',
  SEARCH_USER_LIST: 'videos/user-lists',
  POC_GET_SUBMISSION_LIST: 'poc/video-submission/get-submission',
  POC_UPLOAD_REACTION_VIDEO: 'poc/video-reaction/video-upload',
  POC_SUBMIT_REACTION_VIDEO: 'poc/video-submission/upload-submission',
  POC_GET_RECORD_REACTION_VIDEO: 'poc/video-reaction/get-video',
};

export const ROUTE_PATH = {
  STUDENT_ROUTE: 'student',
  FEEDBACK_ROUTE: 'feedback',
};

export const API_RESPONSE_STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail',
};
export const CAMERA_MODE = ['Front', 'Rear'];

export const VIDEO_ACCESSABILITY = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  PRIVATE_GROUP: 'private_group',
};
export const FOLLOW_PROPERTIES = {
  PRIMARY: 'primary',
  LIGHTGREY: 'lightGrey',
  FOLLOW_TYPE: 'follow',
  OUTLINE_DISABLE: 'outline-disable',
  OUTLINE_PRIMARY: 'outline-primary',
};

export const TO_PATH = {
  ALL: 'all',
  FOLLOWED: 'followed-by-you',
  FOLLOWING: 'following-you',
};
export const FOLLOW_USER_TYPE = {
  FOLLOW_ALL: 'all',
  FOLLOWED_TYPE: 'followed_by_you',
  FOLLOWING_TYPE: 'following_you',
};

export const USER_TYPE = {
  FOLLOW_USER: 'follow',
  FOLLOWING_USER: 'following',
};

export const SUPPORTED_FILE_EXTENSIONS = ['MP4', 'AVI', 'WMV', 'MOV'];

export const THEME_COLORS = {
  primary: 'primary',
  dark: 'dark',
  lightGrey: 'lightGrey',
  lightBlack: 'lightBlack',
  danger: 'danger',
  tertiary: 'tertiary',
  white: 'white',
  lightBlueText: 'lightBlueText',
  success: 'success',
};

export const UPLOADED_FILE_STATUS = {
  webm: ['start-mp4-convert', 'failed-mp4-convert'],
  mp4: ['sucess-mp4-convert', 'start-s3-upload', 'failed-s3-upload'],
  s3: ['success-s3-upload'],
};

export const FILE_SIZE = 71680;
export const TITLE_CHAR_LIMIT = 28;
