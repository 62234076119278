import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sendData } from 'services';
import { APIS } from 'utils/constants';

/**
 * Name: useIsMounted
 * Desc: Identify the component is mounted or not
 */
const useVideosFetch = (setLoading = () => {}, reqParams) => {
  const [videos, setVideos] = useState([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const { lastVideoAdded } = useSelector((state) => state.videoUpload);

  const getVideos = async (params = null, isLoading = true) => {
    setLoading(isLoading);
    const { hasError, data, x_total_count: recordCount } = await sendData(
      APIS.GET_MY_VIDEOS,
      'post',
      params || reqParams,
    );
    setTotalRecordCount(recordCount);
    if (!hasError) {
      setVideos(data);
    }
    setLoading(false);
  };

  const fetchVideos = useCallback(() => {
    getVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos, lastVideoAdded]);

  return { totalRecordCount, videos, getVideos };
};

export default useVideosFetch;
