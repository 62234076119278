import { useCallback, useEffect, useState } from 'react';
import { sendData } from 'services';
import { APIS } from 'utils/constants';

export const useHeaderNotificationBell = (userInfo) => {
  const { user_id: userId = '' } = userInfo;
  const [notificationCount, setNotificationCount] = useState(0);

  const reqParams = {
    reqParam: {
      ownerUserId: userId,
      fetchFor: 'latest',
      sort: { updated_at: -1 },
      perRecords: 10,
    },
  };
  const getNotificationsCount = async (params = null) => {
    const { hasError, Notify_Count: recordCount } = await sendData(
      APIS.GET_MY_NOTIFICATIONS,
      'post',
      params || reqParams,
    );

    if (!hasError) {
      setNotificationCount(recordCount);
    }
  };

  const fetchNotificationsCount = useCallback(() => {
    getNotificationsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchNotificationsCount();
    const interval = setInterval(() => {
      fetchNotificationsCount();
    }, 1200000);
    return () => clearInterval(interval);
  }, [fetchNotificationsCount]);

  const reqParamsGetUnReadCount = {
    reqParam: {
      notificationIds: [],
      ownerUserId: userId,
      actionType: 'clearLatestCount',
    },
  };

  const handleOnClick = async () => {
    const { hasError } = await sendData(
      APIS.UPDATE_MY_NOTIFICATIONS,
      'put',
      reqParamsGetUnReadCount,
    );
    if (!hasError) {
      setNotificationCount(0);
    }
  };

  return { notificationCount, handleOnClick };
};
