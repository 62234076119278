import FileSaver from 'file-saver';
import { SUPPORTED_FILE_EXTENSIONS } from './constants';

export const noop = () => {};

export const getVideoServerUrl = () => {
  return process.env.REACT_APP_FILE_PATH;
};
export const getApiUrl = () => {
  return process.env.REACT_APP_API_BASE_URL;
};
export const getVideoThumbUrl = (imageName) => {
  return `${getApiUrl()}videos_thumbnail/${imageName}`;
};
export const getProfileImagePath = (imageName) => {
  return `${getApiUrl()}profile_pic/${imageName}`;
};

export const getHostUrl = () => {
  return process.env.REACT_APP_HOST_URL;
};
export const getWebSocketUrl = () => {
  return process.env.REACT_APP_WEB_SOCKET_URL;
};
export const getFileBaseUrl = () => {
  return process.env.REACT_APP_FILE_PATH;
};

export const getBucketInfo = () => {
  return {
    path: process.env.REACT_APP_BUCKET_PATH,
    profilePath: process.env.REACT_APP_BUCKET_PROFILE_PATH,
    name: process.env.REACT_APP_BUCKET_NAME,
    accessKeyId: process.env.REACT_APP_BUCKET_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_BUCKET_SECRET_ACCESS_KEY_ID,
    expires: Number(process.env.REACT_APP_BUCKET_EXPIRES_TIME),
  };
};

export const getDomElement = (kay) => {
  return document.querySelector(kay) || null;
};

export const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const encodeBase64 = (data) => {
  return window.btoa(data); // window.btoa(`${ENCRYPTION_KEY}${JSON.stringify(data)}`);
};

export const decodeBase64 = (encodedString) => {
  const dataString = window.atob(encodedString);
  return dataString; // dataString.split(ENCRYPTION_KEY)[1];
};

export const formateDate = (date) => {
  return new Date(date).toLocaleDateString();
};

export const formateJoinDate = (date) => {
  const d = new Date(date);

  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };
  return d.toLocaleDateString('en-US', options);
};
export const padStart = (digit) => {
  return digit.toString().padStart(2, '0');
};
export const formateDurationTime = (timeInSec) => {
  const hours = Math.floor(timeInSec / (60 * 60));

  const divisorMinutes = timeInSec % (60 * 60);
  const minutes = Math.floor(divisorMinutes / 60);

  const divisorSeconds = parseInt(divisorMinutes % 60, 10);
  const seconds = Math.ceil(divisorSeconds);

  return `${hours ? `${padStart(hours)}:` : ''}${padStart(minutes)}:${padStart(
    seconds,
  )}`;
};

export const generateUniqueId = () => {
  return new Date().getTime();
};

export const getExtension = (fileName) => {
  const parts = fileName.split('.');
  return parts[parts.length - 1];
};

export const getStringPart = (name, splitType, partNumber) => {
  const parts = name.split(splitType);
  return parts[partNumber];
};

export const isVideo = (fileName, type) => {
  const ext = getExtension(fileName);
  if (
    (ext && SUPPORTED_FILE_EXTENSIONS.includes(ext.toLowerCase())) ||
    type.toLowerCase().search('audio') > -1
  ) {
    return true;
  }
  return false;
};

export const scrollToView = (selector) => {
  setTimeout(() => {
    const view = getDomElement(selector);
    view.scrollIntoView(true);
  }, 500);
};

export const isValidEmail = (email) => {
  const emailValid = !/\S+@\S+\.\S+/.test(email);
  return emailValid;
};

export const replaceTextFunction = (message, search, replace) => {
  const replaceMessage = message.replace(search, replace);
  return replaceMessage;
};
export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, encodeBase64(JSON.stringify(value)));
};
export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};
export const getLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  return (data && JSON.parse(decodeBase64(data))) || {};
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getVideoDuration = (file) => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const { duration } = video;
      resolve(duration);
    };
    video.onerror = () => {
      resolve(0);
    };
    video.src = URL.createObjectURL(file);
  }).catch(() => {
    return 0;
  });
};

export const getVideoDimension = (file) => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      resolve({ width: video.videoWidth, height: video.videoHeight });
    };
    video.onerror = () => {
      resolve(0);
    };
    video.src = URL.createObjectURL(file);
  }).catch(() => {
    return 0;
  });
};
export const uniqueArray = (array, key) => {
  return [...new Map(array.map((item) => [item[key], item])).values()];
};

export const timeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} years ago`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} months ago`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} days ago`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} hours ago`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} minutes ago`;
  }

  return Math.floor(seconds) > 59
    ? `${Math.floor(seconds)} seconds ago`
    : `just now`;
};

export function formatTime(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  // const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
}

export const getFirstChar = (firstName, lastName) => {
  let profileImageText = '';
  if (firstName && lastName) {
    profileImageText = firstName.charAt(0) + lastName.charAt(0);
  } else if (firstName && firstName.length >= 2) {
    profileImageText = firstName.charAt(0) + firstName.charAt(1);
  }
  return profileImageText;
};

export const base64toBlob = (dataURI) => {
  if (dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }
  return '';
};
export const capitalizeFirstChar = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getVideoDownload = (videoUrl, title) => {
  FileSaver.saveAs(videoUrl, title);
};

export const getRendomNumber = (max = 10000) => {
  return Math.floor(Math.random() * max);
};

export const getUniqueUserFeedbackList = (feedback, loggedInUserId = '') => {
  // Added filter for unique user list
  const userListArray = [
    ...new Set(
      feedback
        .map((item) => item.user)
        .filter((element) => element !== undefined),
    ),
  ];

  // sorted array here to show user profile even he once turn off anonymous setting
  const sortedAnonymousArray = userListArray.sort((a) =>
    a.is_anonymous ? -1 : 1,
  );

  const key = '_id';
  const uniqueUserList = [
    ...new Map(sortedAnonymousArray.map((item) => [item[key], item])).values(),
  ];
  // Added filter for loggedin user id
  return uniqueUserList.filter((item) => item._id !== loggedInUserId);
};

export const getUserNotificationStatus = (
  notification = [],
  reciverId,
  creatorUserId,
) => {
  const userList = [
    ...new Set(
      notification
        .filter(
          (item) =>
            item.receiver_user_id === reciverId &&
            item.creator_user_id === creatorUserId &&
            !item.read_status,
        )
        .values(),
    ),
  ];

  return userList.some((item) => item.read_status === false);
};

export const isEmptyObject = (obj) => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

export const getSearchObject = (text) => {
  return { text };
};

export const getReactionVideoServerUrl = () => {
  return process.env.REACT_APP_BUCKET_REACTION_VIDEO_URL;
};

export function isWindowMachine(window) {
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    return true;
  }
  return false;
}

export function isMacMachine(window) {
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    return true;
  }
  return false;
}

export function isLinuxMachine(window) {
  if (window.navigator.appVersion.indexOf('Linux') !== -1) {
    return true;
  }
  return false;
}
