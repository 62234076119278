export const authConstants = {
  LOGIN_LOADING: 'LOGIN_LOADING',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
};

export const notificationConstants = {
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
};
export const videoUploadConstants = {
  UPDATE_LAST_VIDEO: 'UPDATE_LAST_VIDEO',
};
export const collectionAddSuccess = {
  UPDATE_LAST_COLLECTION: 'UPDATE_LAST_COLLECTION',
};
