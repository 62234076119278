import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sendData } from 'services';
import { APIS } from 'utils/constants';

/**
 * Name: useIsMounted
 * Desc: Identify the component is mounted or not
 */
const useCollectionsFetch = (setLoading = () => {}, reqParams) => {
  const [collections, setCollections] = useState([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const { lastCollectionAdded } = useSelector((state) => state.addCollection);

  const getCollections = async (params = null, isLoading = true) => {
    setLoading(isLoading);
    const { hasError, data, x_total_count: recordCount } = await sendData(
      APIS.GET_ALL_COLLECTION,
      'post',
      params || reqParams,
    );
    setTotalRecordCount(recordCount);
    if (!hasError) {
      setCollections(data);
    }
    setLoading(false);
  };

  const fetchCollections = useCallback(() => {
    getCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCollections();
  }, [fetchCollections, lastCollectionAdded]);

  return { totalRecordCount, collections, getCollections };
};

export default useCollectionsFetch;
